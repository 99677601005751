exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agency-portfolio-tsx": () => import("./../../../src/pages/agency-portfolio.tsx" /* webpackChunkName: "component---src-pages-agency-portfolio-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-aurora-tsx": () => import("./../../../src/pages/aurora.tsx" /* webpackChunkName: "component---src-pages-aurora-tsx" */),
  "component---src-pages-customer-experience-tsx": () => import("./../../../src/pages/customer-experience.tsx" /* webpackChunkName: "component---src-pages-customer-experience-tsx" */),
  "component---src-pages-data-platform-tsx": () => import("./../../../src/pages/data-platform.tsx" /* webpackChunkName: "component---src-pages-data-platform-tsx" */),
  "component---src-pages-design-portfolio-tsx": () => import("./../../../src/pages/design-portfolio.tsx" /* webpackChunkName: "component---src-pages-design-portfolio-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-govtech-tsx": () => import("./../../../src/pages/govtech.tsx" /* webpackChunkName: "component---src-pages-govtech-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innovation-tsx": () => import("./../../../src/pages/innovation.tsx" /* webpackChunkName: "component---src-pages-innovation-tsx" */),
  "component---src-pages-lead-tsx": () => import("./../../../src/pages/lead.tsx" /* webpackChunkName: "component---src-pages-lead-tsx" */),
  "component---src-pages-lead-v-1-tsx": () => import("./../../../src/pages/lead-v1.tsx" /* webpackChunkName: "component---src-pages-lead-v-1-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-pulse-tsx": () => import("./../../../src/pages/pulse.tsx" /* webpackChunkName: "component---src-pages-pulse-tsx" */),
  "component---src-pages-rain-demo-tsx": () => import("./../../../src/pages/rain/demo.tsx" /* webpackChunkName: "component---src-pages-rain-demo-tsx" */),
  "component---src-pages-rain-tsx": () => import("./../../../src/pages/rain.tsx" /* webpackChunkName: "component---src-pages-rain-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-thor-demo-tsx": () => import("./../../../src/pages/thor/demo.tsx" /* webpackChunkName: "component---src-pages-thor-demo-tsx" */),
  "component---src-pages-thor-tsx": () => import("./../../../src/pages/thor.tsx" /* webpackChunkName: "component---src-pages-thor-tsx" */),
  "component---src-templates-design-portfolio-detail-tsx": () => import("./../../../src/templates/DesignPortfolioDetail.tsx" /* webpackChunkName: "component---src-templates-design-portfolio-detail-tsx" */)
}

